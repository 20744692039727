@import "abstracts/_abstracts";
.AppNavigation {
	@include media('<=sm') {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		width: 320px;
		max-width: calc(100vw - 2rem);
		background: var(--colorThemeAccent);
		box-shadow: 0 0 32px #0000005c;
		padding: 0;
		z-index: var(--layerPageZIndex);
		overflow: auto;
		transition: var(--animationBase);
		transform: translateX(100%);
		opacity: 0;

		&.is-opened {
			transform: translateX(0);
			opacity: 1;
		}
	}
	
	&-closer {
		position: absolute;
		top: 0;
		right: 0;
		width: 2em;
		height: 2em;
		display: flex;
		justify-content: center;
		align-items: center;
		background: var(--colorBrand);
		color: text-contrast(var(--colorBrand));
		transition: var(--animationBase);

		@include on-event {
			background: lightness(var(--colorBrand), .8);
		}

		@include media('>sm') {
			display: none;
		}
	}

	&-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		gap: 1rem;

		@include media('<=sm') {
			gap: 0;
			flex-direction: column;
			padding: var(--spaceLg);
		}
	}

	&-button {
		margin-left: var(--spaceLg);
		width: calc(100% - var(--spaceLg) - var(--spaceLg));
		justify-content: center;

		@include media('>sm') {
			display: none;
		}
	}
}